import { Navigate, useNavigate, useRoutes } from 'react-router-dom';
import { technicalItineraryRouteConfig } from './technicalItinerary.routes';
import IntroductionContentPage from './pages/IntroductionContentPage/IntroductionContentPage';
import GlobalCharacteristicsActionPage from './pages/GlobalCharacteristicsActionPage/GlobalCharacteristicsActionPage';
import OperationTimelineActionPage from './pages/OperationTimelineActionPage/OperationTimelineActionPage';
import OperationContextualPanel from './pages/OperationContextualPanel/OperationContextualPanel';
import { OrganicFertiliserActionPage } from '@modules/encoding/modules/technicalItinerary/module/organicFertiliser/pages/OrganicFertiliserActionPage/OrganicFertiliserActionPage';
import { MineralFertiliserActionPage } from '@modules/encoding/modules/technicalItinerary/module/mineralFertiliser/pages/MineralFertiliserActionPage';
import { CoverCropActionPage } from './pages/CoverCropActionPage/CoverCropActionPage';
import { HarvestActionPage } from '@modules/encoding/modules/technicalItinerary/module/harvest/pages/HarvestActionPage/HarvestActionPage';
import { DestructionActionPage } from './module/destruction/pages/DestructionActionPage/DestructionActionPage';
import { encodingShared } from '@modules/encoding/shared';
import OperationPloughingFormActionPage from '@modules/encoding/modules/technicalItinerary/pages/OperationPloughingFormActionPage/OperationPloughingFormActionPage';
import { OperationDuplicationActionPanel } from './pages/OperationDuplicationActionPanel/OperationDuplicationActionPanel';
import { OperationDuplicationActionPanelSkeleton } from '@modules/encoding/modules/technicalItinerary/pages/OperationDuplicationActionPanel/OperationDuplicationActionPanelSkeleton';
import { ActionPageSkeleton } from '@modules/encoding/shared/components/ActionPage/ActionPageSkeleton';
import OperationDeepWorkMachineFormActionPage from '@modules/encoding/modules/technicalItinerary/pages/OperationDeepWorkMachineFormActionPage/OperationDeepWorkMachineFormActionPage';
import OperationShallowWorkMachineFormActionPage from '@modules/encoding/modules/technicalItinerary/pages/OperationShallowWorkMachineFormActionPage/OperationShallowWorkMachineFormActionPage';
import { SprayingFormActionPage } from './module/spraying/pages/SprayingFormActionPage/SprayingFormActionPage';
import { SeedingActionPage } from '@modules/encoding/modules/technicalItinerary/module/seeding/pages/SeedingActionPage/SeedingActionPage';
import WeedingMachineActionPage from './module/weedingMachine/pages/WeedingMachineActionPage/WeedingMachineActionPage';
import { NOT_FOUND_ROUTE } from '@shared/errors';
import useCurrentSeasonId from '@modules/encoding/shared/hooks/useCurrentSeasonId';
import { Guard } from '@shared/guards/Guard';
import { HasFieldCropGuard } from '@shared/guards/HasFieldCropGuard';
import { HasAtLeastTwoFieldsForCropGuard } from '@shared/guards/HasAtLeastTwoFieldsForCropGuard';
import { HasFieldCropOperationsGuard } from '@shared/guards/HasFieldCropOperationsGuard';
import { IsStepVisibleGuard } from '@shared/guards/IsStepVisibleGuard';
import { ArePreviousStepCompletedGuard } from '@shared/guards/ArePreviousStepCompletedGuard';

export default () => {
    const { currentSeasonId } = useCurrentSeasonId();
    const navigate = useNavigate();

    const routes = useRoutes([
        {
            path: technicalItineraryRouteConfig.introduction,
            element: (
                <Guard
                    element={<IntroductionContentPage />}
                    guards={[IsStepVisibleGuard, ArePreviousStepCompletedGuard]}
                />
            ),
        },
        {
            path: technicalItineraryRouteConfig.fieldCrop.base,
            element: <OperationContextualPanel navigate={navigate} />,
            children: [
                {
                    path: technicalItineraryRouteConfig.fieldCrop.globalCharacteristics,
                    element: (
                        <Guard
                            loader={<ActionPageSkeleton />}
                            element={<GlobalCharacteristicsActionPage navigate={navigate} />}
                            guards={[HasFieldCropGuard, IsStepVisibleGuard, ArePreviousStepCompletedGuard]}
                        />
                    ),
                },
                {
                    path: technicalItineraryRouteConfig.fieldCrop.operationsTimeline,
                    element: (
                        <Guard
                            loader={<ActionPageSkeleton />}
                            element={<OperationTimelineActionPage navigate={navigate} />}
                            guards={[HasFieldCropGuard, IsStepVisibleGuard, ArePreviousStepCompletedGuard]}
                        />
                    ),
                },
                {
                    path: technicalItineraryRouteConfig.fieldCrop.operationDuplication,
                    element: (
                        <Guard
                            loader={<OperationDuplicationActionPanelSkeleton />}
                            element={<OperationDuplicationActionPanel navigate={navigate} />}
                            guards={[
                                HasFieldCropGuard,
                                HasAtLeastTwoFieldsForCropGuard,
                                IsStepVisibleGuard,
                                ArePreviousStepCompletedGuard,
                            ]}
                        />
                    ),
                },
                {
                    path: technicalItineraryRouteConfig.fieldCrop.operations.sprayingOperationForm,
                    element: (
                        <Guard
                            loader={<ActionPageSkeleton />}
                            element={<SprayingFormActionPage navigate={navigate} />}
                            guards={[
                                HasFieldCropGuard,
                                HasFieldCropOperationsGuard,
                                IsStepVisibleGuard,
                                ArePreviousStepCompletedGuard,
                            ]}
                        />
                    ),
                },
                {
                    path: technicalItineraryRouteConfig.fieldCrop.operations.coverCropSeedingOperationForm,
                    element: (
                        <Guard
                            loader={<ActionPageSkeleton />}
                            element={<CoverCropActionPage navigate={navigate} />}
                            guards={[
                                HasFieldCropGuard,
                                HasFieldCropOperationsGuard,
                                IsStepVisibleGuard,
                                ArePreviousStepCompletedGuard,
                            ]}
                        />
                    ),
                },
                {
                    path: technicalItineraryRouteConfig.fieldCrop.operations.deepWorkMachineOperationForm,
                    element: (
                        <Guard
                            loader={<ActionPageSkeleton />}
                            element={<OperationDeepWorkMachineFormActionPage navigate={navigate} />}
                            guards={[
                                HasFieldCropGuard,
                                HasFieldCropOperationsGuard,
                                IsStepVisibleGuard,
                                ArePreviousStepCompletedGuard,
                            ]}
                        />
                    ),
                },
                {
                    path: technicalItineraryRouteConfig.fieldCrop.operations.destructionOperationForm,
                    element: (
                        <Guard
                            loader={<ActionPageSkeleton />}
                            element={<DestructionActionPage navigate={navigate} />}
                            guards={[
                                HasFieldCropGuard,
                                HasFieldCropOperationsGuard,
                                IsStepVisibleGuard,
                                ArePreviousStepCompletedGuard,
                            ]}
                        />
                    ),
                },
                {
                    path: technicalItineraryRouteConfig.fieldCrop.operations.harvestOperationForm,
                    element: (
                        <Guard
                            loader={<ActionPageSkeleton />}
                            element={<HarvestActionPage navigate={navigate} />}
                            guards={[
                                HasFieldCropGuard,
                                HasFieldCropOperationsGuard,
                                IsStepVisibleGuard,
                                ArePreviousStepCompletedGuard,
                            ]}
                        />
                    ),
                },
                {
                    path: technicalItineraryRouteConfig.fieldCrop.operations.mineralFertilisationOperationForm,
                    element: (
                        <Guard
                            loader={<ActionPageSkeleton />}
                            element={<MineralFertiliserActionPage navigate={navigate} />}
                            guards={[
                                HasFieldCropGuard,
                                HasFieldCropOperationsGuard,
                                IsStepVisibleGuard,
                                ArePreviousStepCompletedGuard,
                            ]}
                        />
                    ),
                },
                {
                    path: technicalItineraryRouteConfig.fieldCrop.operations.organicFertilisationOperationForm,
                    element: (
                        <Guard
                            loader={<ActionPageSkeleton />}
                            element={<OrganicFertiliserActionPage navigate={navigate} />}
                            guards={[
                                HasFieldCropGuard,
                                HasFieldCropOperationsGuard,
                                IsStepVisibleGuard,
                                ArePreviousStepCompletedGuard,
                            ]}
                        />
                    ),
                },
                {
                    path: technicalItineraryRouteConfig.fieldCrop.operations.seedingOperationForm,
                    element: (
                        <Guard
                            loader={<ActionPageSkeleton />}
                            element={<SeedingActionPage navigate={navigate} />}
                            guards={[
                                HasFieldCropGuard,
                                HasFieldCropOperationsGuard,
                                IsStepVisibleGuard,
                                ArePreviousStepCompletedGuard,
                            ]}
                        />
                    ),
                },
                {
                    path: technicalItineraryRouteConfig.fieldCrop.operations.companionSeedingOperationForm,
                    element: (
                        <Guard
                            loader={<ActionPageSkeleton />}
                            element={<SeedingActionPage navigate={navigate} />}
                            guards={[
                                HasFieldCropGuard,
                                HasFieldCropOperationsGuard,
                                IsStepVisibleGuard,
                                ArePreviousStepCompletedGuard,
                            ]}
                        />
                    ),
                },
                {
                    path: technicalItineraryRouteConfig.fieldCrop.operations.shallowWorkMachineOperationForm,
                    element: (
                        <Guard
                            loader={<ActionPageSkeleton />}
                            element={<OperationShallowWorkMachineFormActionPage navigate={navigate} />}
                            guards={[
                                HasFieldCropGuard,
                                HasFieldCropOperationsGuard,
                                IsStepVisibleGuard,
                                ArePreviousStepCompletedGuard,
                            ]}
                        />
                    ),
                },
                {
                    path: technicalItineraryRouteConfig.fieldCrop.operations.weedingMachineOperationForm,
                    element: (
                        <Guard
                            loader={<ActionPageSkeleton />}
                            element={<WeedingMachineActionPage navigate={navigate} />}
                            guards={[
                                HasFieldCropGuard,
                                HasFieldCropOperationsGuard,
                                IsStepVisibleGuard,
                                ArePreviousStepCompletedGuard,
                            ]}
                        />
                    ),
                },
                {
                    path: technicalItineraryRouteConfig.fieldCrop.operations.ploughOperationForm,
                    element: (
                        <Guard
                            loader={<ActionPageSkeleton />}
                            element={<OperationPloughingFormActionPage navigate={navigate} />}
                            guards={[
                                HasFieldCropGuard,
                                HasFieldCropOperationsGuard,
                                IsStepVisibleGuard,
                                ArePreviousStepCompletedGuard,
                            ]}
                        />
                    ),
                },
            ],
        },
        { path: '*', element: <Navigate to={`/${currentSeasonId}${NOT_FOUND_ROUTE}`} /> },
    ]);

    return (
        <encodingShared.FadeTransition transitionKey={routes?.props?.match?.pathnameBase as string | undefined}>
            {routes}
        </encodingShared.FadeTransition>
    );
};
