import entities, { StaticLivestockT } from '@shared/entities';
import { useCallback, useMemo } from 'react';
import { LivestockT } from '@shared/entities/livestock/livestock.types';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { encodingShared } from '@modules/encoding/shared';
import { FORM_SUBMIT_DEBOUNCE_TIME_MS } from '@shared/constants/formsConfig';
import { useStableDebounce } from '@shared/hooks/useStableDebounce';
import { NavigateFunction } from 'react-router-dom';
import { livestockRoutesConfig } from '../livestock.routes';
import { PROGRESS_SLUGS } from '@shared/entities/progress/progress.types';
import { useFarmSeasonReadOnly } from '@modules/encoding/shared/hooks/useFarmSeasonReadOnly';

// Helper function to sort static data alphabetically according to the translation
export const sortDataAlphabetically = <T extends { translation_slug: string }>(data: T[], t: TFunction): T[] => {
    return [...data].sort((a, b) => {
        return t(a.translation_slug).localeCompare(t(b.translation_slug));
    });
};

export const useLivestockDetailsForm = ({ navigate }: { navigate: NavigateFunction }) => {
    const { currentSeasonId } = encodingShared.useCurrentSeasonId();
    const { t } = useTranslation();
    const { readOnly } = useFarmSeasonReadOnly();

    // Get static livestock
    const { staticLivestockState } = entities.staticLivestock.useState();
    const staticLivestockOptions = staticLivestockState.list;

    // Get farm season livestock
    const { livestockState } = entities.livestock.useState({ farmSeasonId: currentSeasonId });
    const [deleteLivestock] = entities.livestock.useDelete();
    const [createLivestock] = entities.livestock.useCreate();
    const [updateLivestock] = entities.livestock.useUpdate();
    const [updateLivestockProgress, { isLoading: recomputeProgressLoading }] = entities.livestock.useUpdateProgress();
    const { progressState } = entities.progress.useState({ seasonId: currentSeasonId });
    const livestockProgressDone = progressState.getBySlug(PROGRESS_SLUGS.LIVESTOCK)?.progress_done ?? 0;
    const livestockData = livestockState.list;

    // Sorting datas and static datas alphabetically
    const sortedStaticLivestock = useMemo(
        () => sortDataAlphabetically(staticLivestockOptions, t),
        [staticLivestockOptions, t],
    );
    const sortedLivestockData: LivestockT[] = useMemo(() => {
        return [...livestockData].sort((a, b) => {
            const indexA: number = sortedStaticLivestock.findIndex((el) => el.id === a.livestock_id);
            const indexB: number = sortedStaticLivestock.findIndex((el) => el.id === b.livestock_id);
            if (indexA !== undefined && indexB !== undefined) {
                return indexA - indexB;
            } else {
                return 0;
            }
        });
    }, [livestockData, sortedStaticLivestock]);

    const selectedStaticLivestock = useMemo(() => {
        const sortedLivestockDataLivestockIds = sortedLivestockData.map((el) => el.livestock_id);
        return sortedStaticLivestock.filter((el) => sortedLivestockDataLivestockIds.includes(el.id));
    }, [sortedStaticLivestock, sortedLivestockData]);

    // Compute the progress (each selected livestock is > 0)
    const isDone = useMemo(
        () => (sortedLivestockData.length > 0 ? sortedLivestockData.every((el) => el?.quantity || 0 > 0) : false),
        [sortedLivestockData],
    );

    /*
     * The currently updated datas are temporarily disabled during the update process to prevent users from clicking checkboxes multiple times
     * which could cause inconsistencies between the database and the cache.
     */
    const updatingLivestock = useMemo(() => sortedLivestockData.filter((el) => el.id < 0), [sortedLivestockData]);
    const updatingStaticLivestock = useMemo(() => {
        const updatingIds = updatingLivestock.map((el) => el.livestock_id);
        return staticLivestockOptions?.filter((el) => updatingIds.includes(el.id));
    }, [staticLivestockOptions, updatingLivestock]);

    // Handlers
    const handleLivestockChange = useCallback(
        async (newValues: StaticLivestockT[]) => {
            const newIds = new Set(newValues.map((el) => el.id));
            const oldIds = new Set(sortedLivestockData.map((el) => el.livestock_id));

            const removedId = [...oldIds].find((id) => !newIds.has(id));
            const addedId = [...newIds].find((id) => !oldIds.has(id));

            if (removedId) {
                const removedLivestock = sortedLivestockData.find((el) => el.livestock_id === removedId);
                if (removedLivestock) {
                    await deleteLivestock({
                        farmSeasonId: currentSeasonId,
                        farmSeasonLivestockId: removedLivestock.id,
                    });
                }
            }

            if (addedId) {
                const addedLivestock = staticLivestockOptions.find((el) => el.id === addedId);
                if (addedLivestock) {
                    await createLivestock({
                        farmSeasonId: currentSeasonId,
                        body: {
                            livestock_id: addedLivestock.id,
                            quantity: null,
                            farm_season_id: currentSeasonId,
                        },
                    });
                }
            }
        },
        [currentSeasonId, sortedLivestockData, staticLivestockOptions, deleteLivestock, createLivestock],
    );

    const onUpdateQuantity = useStableDebounce((livestock: LivestockT) => {
        updateLivestock({
            farmSeasonId: currentSeasonId,
            farmSeasonLivestockId: livestock.id,
            body: {
                farm_season_id: currentSeasonId,
                livestock_id: livestock.livestock_id,
                quantity: livestock.quantity,
            },
        });
    }, FORM_SUBMIT_DEBOUNCE_TIME_MS);

    const onDelete = useCallback(
        ({ livestockId }: { livestockId: number }) => {
            deleteLivestock({ farmSeasonId: currentSeasonId, farmSeasonLivestockId: livestockId });
        },
        [currentSeasonId, deleteLivestock],
    );

    // Loading states
    const isLoading = staticLivestockState.isLoading || livestockState.isLoading || progressState.isLoading;

    const handleContinue = useCallback(async () => {
        // Update the progress if the livestock datas are validated but the livestock progress is not yet updated
        if (isDone && livestockProgressDone === 0) {
            await updateLivestockProgress({ farmSeasonId: currentSeasonId });
        }
        navigate(`${livestockRoutesConfig.introduction}`);
    }, [currentSeasonId, isDone, livestockProgressDone, navigate, updateLivestockProgress]);

    return {
        staticLivestock: sortedStaticLivestock,
        livestock: sortedLivestockData,
        selectedStaticLivestock,
        updatingLivestock,
        updatingStaticLivestock,
        handleLivestockChange,
        handleContinue,
        onUpdateQuantity,
        onDelete,
        isLoading,
        isDone,
        recomputeProgressLoading,
        readOnly,
    };
};
