import { encodingShared } from '@modules/encoding/shared';
import entities from '@shared/entities';
import { NavigateFunction } from 'react-router-dom';
import { livestockRoutesConfig } from '../livestock.routes';
import { useGetCurrentHarvestYear } from '@modules/encoding/modules/technicalItinerary/hooks/useGetCurrentHarvestYear';
import { useProgressTracker } from '@shared/hooks/useProgressTracker';

export const LIVESTOCK_PROGRESS_STATUSES = {
    NOT_STARTED: 'NOT_STARTED',
    FINISHED: 'FINISHED',
} as const;

export type LivestockProgressStatusT = (typeof LIVESTOCK_PROGRESS_STATUSES)[keyof typeof LIVESTOCK_PROGRESS_STATUSES];

export const useIntroductionContentData = ({ navigate }: { navigate: NavigateFunction }) => {
    const { nextStepToComplete, getRouteMatchingStep } = useProgressTracker();
    const { harvestYear, isLoading: isLoadingHarvestYear } = useGetCurrentHarvestYear();
    const { currentSeasonId } = encodingShared.useCurrentSeasonId();
    const { progressState } = entities.progress.useState({ seasonId: currentSeasonId });
    const progressDone = progressState.getBySlug('livestock')?.progress_done ?? 0;
    const progressTotal = progressState.getBySlug('livestock')?.progress_total ?? 0;

    const isDataLoading = isLoadingHarvestYear || progressState.isLoading || progressState.isFetching;

    const progressStatus: LivestockProgressStatusT =
        progressDone === progressTotal ? LIVESTOCK_PROGRESS_STATUSES.FINISHED : LIVESTOCK_PROGRESS_STATUSES.NOT_STARTED;

    const handleOpenLivestockDetails = () => navigate(livestockRoutesConfig.livestockDetails);
    const redirectToNextEncodingStep = () => {
        if (nextStepToComplete) return navigate(`../${getRouteMatchingStep(nextStepToComplete?.slug)}`);
    };

    const handleContinue = () => {
        if (progressStatus === LIVESTOCK_PROGRESS_STATUSES.NOT_STARTED) return handleOpenLivestockDetails();
        if (progressStatus === LIVESTOCK_PROGRESS_STATUSES.FINISHED) return redirectToNextEncodingStep();
        return handleOpenLivestockDetails();
    };

    return {
        harvestYear,
        isDataLoading,
        maxLivestockProgress: progressTotal,
        currentLivestockProgress: progressDone,
        handleOpenLivestockDetails,
        handleContinue,
    };
};
