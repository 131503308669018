import { FieldCropT } from '@shared/entities';
import { BaseEntityT } from '../baseEntity.types';

export type FieldT = {
    farm_season_id: number;
    name: string;
    area: number;
    original_area: number;
    area_source: FieldAreaSourcesT;
    polygon: FieldPolygonT;
    polygon_geometry?: PolygonGeometryT;
    tillage_id: number | null;
    soil_acidity_id: number | null;
    soil_organic_matter_id: number | null;
    is_tillage_permanent: boolean | null;
    is_irrigated: boolean | null;
    is_organic: boolean | null;
    has_drainage: boolean | null;
    has_agroforestry: boolean | null;
    has_cover_crops: boolean | null;
    has_tillage_practice_history: boolean | null;
    has_cover_crop_history: boolean | null;
    has_pasture_history: boolean | null;
    has_organic_fertilization_history: boolean | null;
    is_geom_valid: boolean;
    updated_at: string;
    previous_farm_season_field_id: number | null;
    previous_has_agroforestry: boolean;
    user_area: number | null;
    is_permanent?: boolean;
} & BaseEntityT;

export type FieldUpdatePayloadT = {
    name?: string | null;
    area?: number | null;
    original_area?: number | null;
    area_source?: FieldAreaSourcesT | null;
    polygon?: FieldPolygonT | null;
    polygon_geometry?: PolygonGeometryT | null;
    tillage_id?: number | null;
    soil_acidity_id?: number | null;
    soil_organic_matter_id?: number | null;
    is_tillage_permanent?: boolean | null;
    is_irrigated?: boolean | null;
    is_organic?: boolean | null;
    has_drainage?: boolean | null;
    has_agroforestry?: boolean | null;
    has_cover_crops?: boolean | null;
    has_tillage_practice_history?: boolean | null;
    has_cover_crop_history?: boolean | null;
    has_pasture_history?: boolean | null;
    has_organic_fertilization_history?: boolean | null;
    is_geom_valid?: boolean | null;
    previous_farm_season_field_id?: number | null;
    previous_has_agroforestry?: boolean | null;
};

export type FieldUpdateDrainagePayloadT = {
    has_drainage?: boolean | null;
};

export type FieldEndpointParamsT = {
    farmSeasonId?: number;
};

export type geoJsonFeatureObjectCoordinatePositionT = number[];

export type geoJsonFeatureObjectPolygonT = {
    type: 'Polygon';
    coordinates: geoJsonFeatureObjectCoordinatePositionT[][];
};

export type geoJsonFeatureObjectMultiPolygonT = {
    type: 'MultiPolygon';
    coordinates: geoJsonFeatureObjectCoordinatePositionT[][];
};

/* ------------------------------ Sub Types ------------------------------ */
export const FIELD_AREA_SOURCE_SLUGS = {
    PAC: 'pac',
    OPENDATA: 'opendata',
    DRAWN: 'drawn',
    FORCED: 'forced',
} as const;

export type FieldAreaSourcesT = (typeof FIELD_AREA_SOURCE_SLUGS)[keyof typeof FIELD_AREA_SOURCE_SLUGS];

export type PolygonGeometryT = { type: 'Polygon'; coordinates: number[][][] };

export type FieldPolygonT = {
    type: 'Feature';
    id: number;
    properties: {
        label: string;
        area: number;
        originalFeatureId?: number;
    };
    geometry: {
        type: 'Polygon';
        coordinates: number[][][];
    };
};

/* --------------------------------- ACTIONS -------------------------------- */
// Merge
export type SaveMergedFieldResponseT = {
    field_created: FieldT;
    fields_deleted: number[];
    field_crops_created: FieldCropT[];
};
export type SaveMergedFieldRequestT = {
    farmSeasonId: number;
    body: {
        merged_field: FieldT;
        from_field_ids: number[];
    };
};

// Split
export type SaveSplittedFieldResponseT = {
    fields_created: FieldT[];
    field_deleted: number;
    field_crops_created: FieldCropT[];
};

export type SaveSplittedFieldRequestT = {
    farmSeasonId: number;
    body: {
        splitted_fields: FieldT[];
        from_field_id: number;
    };
};

export type opendataFieldT = {
    name: string;
    area: number;
    user_area: number | null;
    opendata_parcel_id: number;
};

export type drawnFieldT = {
    name: string;
    area: number;
    user_area: number | null;
    geometry: PolygonGeometryT;
};

// Replace Crop
export type ReplaceCropResponseT = {
    field_crop_created: FieldCropT;
    field_crops_deleted: number[];
};
export type ReplaceCropRequestT = FieldEndpointParamsT & {
    fieldId: number;
    body: {
        newCropId: number;
    };
};
