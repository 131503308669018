import useCurrentSeason from '@modules/encoding/shared/hooks/useCurrentSeason';
import { useToggle } from '@shared/hooks/useToggle';
import useCurrentSeasonId from '@modules/encoding/shared/hooks/useCurrentSeasonId';
import { useStartNewSeason } from '@modules/yoy/hooks/useStartNewSeason';
import { useNavigate } from 'react-router-dom';
import { useGetNextFarmSeasonQuery } from '@modules/yoy/api/yoyApi';
import { useEffect } from 'react';
import { useCanOpenNewSeason } from '@shared/hooks/useCanOpenNewSeason';

export const useNewSeasonModalLogic = () => {
    const {
        open: newSeasonModalOpen,
        handleOpen: onNewSeasonModalOpen,
        handleClose: onNewSeasonModalClose,
    } = useToggle();
    const { currentSeason } = useCurrentSeason();
    const navigate = useNavigate();
    const { currentSeasonId } = useCurrentSeasonId();
    const { data: nextFarmSeason, isLoading: isLoadingNextFarmSeason } = useGetNextFarmSeasonQuery({
        farmSeasonId: currentSeasonId,
    });
    const { canOpenNewSeason, isLoading: isLoadingCanOpenNewSeason } = useCanOpenNewSeason();
    const { startNewSeason, isLoading: isStartingNewSeason } = useStartNewSeason({
        onSuccess: (nextFarmSeasonId) => {
            navigate(`/${nextFarmSeasonId}/farm-profile`);
        },
    });
    const hasModalAlreadyBeenShown = !!localStorage.getItem(`newSeasonModalAlreadyDisplayed-${nextFarmSeason?.id}`);
    const showModal = !hasModalAlreadyBeenShown && canOpenNewSeason;

    const isLoading = isLoadingNextFarmSeason || isStartingNewSeason || isLoadingCanOpenNewSeason;

    const handleClose = () => {
        onNewSeasonModalClose();
        localStorage.setItem(`newSeasonModalAlreadyDisplayed-${nextFarmSeason?.id}`, 'true');
    };

    const handleConfirm = async () => {
        if (!currentSeason) return;
        await startNewSeason(currentSeason.id);
        handleClose();
    };

    useEffect(() => {
        if (showModal) onNewSeasonModalOpen();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showModal]);

    return {
        newSeasonModalOpen,
        onNewSeasonModalOpen,
        onNewSeasonModalClose: handleClose,
        showModal,
        isLoading,
        handleConfirm,
    };
};
