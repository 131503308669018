import { makeStyles } from '@soil-capital/ui-kit/style';

export const useFieldSelectionPanelStyle = makeStyles()(() => ({
    relative: {
        position: 'relative',
    },

    panel: {
        position: 'relative',
        flex: 1,
    },
}));
