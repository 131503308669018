import { useGlobalSaveStatusData } from '@modules/encoding/modules/layout/components/GlobalSaveStatus/useGlobalSaveStatus.data';
import { useGlobalSaveStatusLogic } from '@modules/encoding/modules/layout/components/GlobalSaveStatus/useGlobalSaveStatus.logic';
import { useGetCurrentOperationFromUrl } from '@modules/encoding/modules/technicalItinerary/hooks/useGetCurrentOperationFromUrl';
import { useTechnicalItineraryOperationFormRedirect } from '@modules/encoding/modules/technicalItinerary/hooks/useTechnicalItineraryOperationFormRedirect';
import entities from '@shared/entities';
import { useState, useMemo, useEffect } from 'react';
import { NavigateFunction, useParams } from 'react-router-dom';
import { useFieldCropNeedFinalisation } from '@modules/encoding/modules/technicalItinerary/hooks/useFieldCropNeedFinalisation';
import { useOperationListOrder } from '@modules/encoding/modules/technicalItinerary/hooks/useOperationListOrder';
import { useFinalizeDuplicationMutation } from '@modules/encoding/modules/technicalItinerary/module/operationDuplication/api/duplicationApi';
import { useHasAtLeastTwoFieldsForACrop } from '@shared/guards/HasAtLeastTwoFieldsForCropGuard';

export const useOperationFormActionPage = (
    subtitle: string | undefined,
    navigate: NavigateFunction,
    errors: Map<string, string>,
) => {
    const { seasonId, fieldCropId } = useParams();
    const { operation } = useGetCurrentOperationFromUrl();
    const dataContext = useGlobalSaveStatusData();
    const { globalMutationStatus } = useGlobalSaveStatusLogic({ dataContext });
    const hasAtLeastTwoFieldsForACrop = useHasAtLeastTwoFieldsForACrop();

    const { operationState } = entities.operation.useState({
        farmSeasonFieldCropId: Number(fieldCropId),
        farmSeasonId: Number(seasonId),
    });

    const [continueButtonClicked, setContinueButtonClicked] = useState(false);

    const { progressState } = entities.progress.useState({ seasonId: Number(seasonId) }, { skip: !seasonId });
    const { doesFieldCropNeedFinalisation, isLoading: isLoadingFieldCropsThatNeedFinalisation } =
        useFieldCropNeedFinalisation();
    const { isLastOperation } = useOperationListOrder();
    const [finaliseMutation, { isLoading: isFinalisingFieldCrop }] = useFinalizeDuplicationMutation();
    const isPerformingMutation = globalMutationStatus === 'pending';
    const isLoading =
        progressState.isFetching ||
        isPerformingMutation ||
        isLoadingFieldCropsThatNeedFinalisation ||
        isFinalisingFieldCrop;

    const errorDisplayed: [string, string] | undefined = useMemo(() => {
        if (continueButtonClicked) {
            if (errors.size > 0) {
                return errors.entries().next().value;
            }
        }

        return undefined;
    }, [continueButtonClicked, errors]);

    const { navigateToNext, navigateToPrevious } = useTechnicalItineraryOperationFormRedirect(
        operationState.getById(operation?.id),
        navigate,
    );

    const handleBackClick = () => {
        navigateToPrevious();
    };

    const handleContinue = async () => {
        setContinueButtonClicked(true);

        if (operation?.id && isLastOperation(operation?.id) && doesFieldCropNeedFinalisation(Number(fieldCropId))) {
            await finaliseMutation({
                farmSeasonId: Number(seasonId),
                payload: {
                    finalised_field_crop_ids: [Number(fieldCropId)],
                },
            });
        }

        if (errors.size > 0) return;

        navigateToNext();
    };

    const operationSubtitle = subtitle ? subtitle : operation?.operation_date_formatted;

    const continueButtonSlug = operation?.id
        ? isLastOperation(operation?.id) && hasAtLeastTwoFieldsForACrop
            ? 'encoding-technical-itinerary.continue-to-duplication'
            : 'constants.continue'
        : 'constants.continue';
    const isDuplicationButton = continueButtonSlug === 'encoding-technical-itinerary.continue-to-duplication';

    useEffect(() => {
        if (!errors || errors.size === 0) {
            setContinueButtonClicked(false);
        }
    }, [errors, isLoading]);

    return {
        errorDisplayed,
        operationSubtitle,
        continueButtonSlug,
        handleBackClick,
        handleContinueClick: handleContinue,
        isDuplicationButton,
    };
};
