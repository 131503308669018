import useCurrentSeasonId from '@modules/encoding/shared/hooks/useCurrentSeasonId';
import entities from '@shared/entities';
import { ProgressSlugT } from '@shared/entities/progress/progress.types';
import { encodingRoutesConfig } from '@modules/encoding/encoding.routes';
import { useGetProgressStepMatchingCurrentRoute } from './useGetProgressStepMatchingCurrentRoute';

export const useProgressTracker = () => {
    const { currentSeasonId } = useCurrentSeasonId();
    const { progressState } = entities.progress.useState({ seasonId: currentSeasonId });
    const { currentProgressStep } = useGetProgressStepMatchingCurrentRoute();

    const sortedStepsAsc = progressState?.list.sort((a, b) => a.order - b.order);
    const sortedStepsDesc = sortedStepsAsc?.slice()?.reverse();

    const nextProgressStep = currentProgressStep
        ? sortedStepsAsc.find((step) => step.is_visible && step.order > currentProgressStep.order)
        : undefined;

    const firstNotDoneStep = sortedStepsAsc.find(
        (step) => step.progress_total !== step.progress_done && step.is_visible,
    );

    const nextStepToComplete =
        sortedStepsDesc?.find((step) => step.progress_total !== step.progress_done && step.is_visible) ||
        nextProgressStep;

    const lastCompletedStep = sortedStepsDesc?.find(
        (step) => step.progress_total === step.progress_done && step.is_visible,
    );

    const getRouteMatchingStep = (step: ProgressSlugT) => {
        const routeKey = step?.toUpperCase()?.replaceAll('-', '_') as keyof typeof encodingRoutesConfig;
        return encodingRoutesConfig[routeKey];
    };

    return {
        firstNotDoneStep,
        lastCompletedStep,
        nextStepToComplete,
        getRouteMatchingStep,
        isLoading: progressState.isLoading,
    };
};
