import { yoyApi } from '@modules/yoy/api/yoyApi';

export const useStartNewSeason = ({ onSuccess }: { onSuccess?: (nextFarmSeasonId: number) => void } = {}) => {
    const [startMutation, { isLoading }] = yoyApi.useStartNewSeasonMutation();

    const startNewSeason = async (currentFarmSeasonId: number) => {
        try {
            const response = await startMutation({ currentFarmSeasonId }).unwrap();
            const nextFarmSeasonId = response.id;
            onSuccess?.(nextFarmSeasonId);
        } catch (e) {
            throw e;
        }
    };

    return {
        startNewSeason,
        isLoading,
    };
};
