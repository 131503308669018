import { useNavigate } from 'react-router-dom';
import { rotationRoutesConfig } from '../../rotation.routes';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import technicalItineraryRoutes from '@modules/encoding/modules/technicalItinerary/technicalItinerary.routes';
import {
    ROTATION_PROGRESS_STATUSES,
    RotationProgressStatusT,
} from '@modules/encoding/modules/rotation/pages/IntroductionContentPage/useIntroductionContentProgress';
import { encodingRoutesConfig } from '@modules/encoding/encoding.routes';
import { changeFieldId } from '@modules/encoding/modules/rotation/store/filter.slice';

type PropsT = {
    status: RotationProgressStatusT;
};

export const useIntroductionContentPageLogic = ({ status = 'PENDING' }: PropsT) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);

    const handleOpenWithFieldId = (farmSeasonFieldId: number) => {
        dispatch(changeFieldId(farmSeasonFieldId));
        setOpen(true);
    };
    const handleClose = () => setOpen(false);
    const handleRedirectToPac = () => navigate(rotationRoutesConfig.pacImport);
    const handleRedirectToTechnicalItinerary = () =>
        navigate(`../${encodingRoutesConfig.TECHNICAL_ITINERARY}/${technicalItineraryRoutes.introduction()}`);

    const handleContinue = () => {
        if (status === ROTATION_PROGRESS_STATUSES.FINISHED) return handleRedirectToTechnicalItinerary();
        if (status === ROTATION_PROGRESS_STATUSES.PENDING) return handleOpen();
        return handleRedirectToPac();
    };

    return {
        open,
        handleOpen,
        handleOpenWithFieldId,
        handleContinue,
        handleClose,
        handleRedirectToPac,
    };
};
