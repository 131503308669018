import { makeStyles } from '@soil-capital/ui-kit/style';

export const useSkeletonContainerStyle = makeStyles()((theme) => ({
    skeletonContainer: {
        position: 'relative',
    },
    skeleton: {
        position: 'absolute',
        zIndex: 900,
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        transition: `opacity ${theme.transitions.easing.easeIn} ${theme.transitions.duration.leavingScreen}ms, visibility ${theme.transitions.duration.leavingScreen}ms ${theme.transitions.duration.leavingScreen}ms`,
        opacity: 0,
        visibility: 'hidden',
        backgroundColor: theme.palette.common.white,
    },
    skeletonLoading: {
        opacity: '1 !important',
        visibility: 'visible !important' as 'visible',
    },
    overflowHidden: {
        overflow: 'hidden',
    },
}));
