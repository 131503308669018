import { useGetCurrentHarvestYear } from '@modules/encoding/modules/technicalItinerary/hooks/useGetCurrentHarvestYear';
import useCurrentSeasonId from '@modules/encoding/shared/hooks/useCurrentSeasonId';
import entities from '@shared/entities';
import { PROGRESS_SLUGS } from '@shared/entities/progress/progress.types';
import { useProgressTracker } from '@shared/hooks/useProgressTracker';
import { useToggle } from '@shared/hooks/useToggle';
import { NavigateFunction } from 'react-router-dom';

export const useIntroductionContentData = ({ navigate }: { navigate: NavigateFunction }) => {
    const { nextStepToComplete, getRouteMatchingStep } = useProgressTracker();
    const { harvestYear, isLoading: harvestYearIsLoading } = useGetCurrentHarvestYear();
    const { currentSeasonId } = useCurrentSeasonId();
    const { fieldState } = entities.field.useState({ farmSeasonId: currentSeasonId });
    const { progressState } = entities.progress.useState({ seasonId: currentSeasonId });
    const maxDrainageDefinitionProgress = progressState.getBySlug(PROGRESS_SLUGS.DRAINAGE)?.progress_total || 1;
    const currentDrainageDefinitionProgress = progressState.getBySlug(PROGRESS_SLUGS.DRAINAGE)?.progress_done || 0;

    const isDataLoading = harvestYearIsLoading || fieldState.isLoading || progressState.isLoading;

    const {
        open: isDrainageFieldSelectionModalOpen,
        handleOpen: openDrainageFieldSelectionModal,
        handleClose: closeDrainageFieldSelectionModal,
    } = useToggle();

    const redirectToNextEncodingStep = () => {
        if (nextStepToComplete) return navigate(`../${getRouteMatchingStep(nextStepToComplete?.slug)}`);
    };

    const handleContinueClicked = () => {
        if (currentDrainageDefinitionProgress === maxDrainageDefinitionProgress) {
            redirectToNextEncodingStep();
        } else {
            openDrainageFieldSelectionModal();
        }
    };

    return {
        isDataLoading,
        harvestYear,
        handleContinueClicked,
        maxDrainageDefinitionProgress,
        currentDrainageDefinitionProgress,
        isDrainageFieldSelectionModalOpen,
        openDrainageFieldSelectionModal,
        closeDrainageFieldSelectionModal,
    };
};
