import { MapRoot } from '@shared/map2';
import { SidePanel } from '@soil-capital/ui-kit/components';
import { Typography, Skeleton } from '@soil-capital/ui-kit/material-core';
import { useFieldSelectionPanelStyle } from './useFieldSelectionPanelStyle';
import { FieldSelectionList } from '../FieldSelectionList/FieldSelectionList';
import { useFieldSelectionPanelLogic } from './useFieldSelectionPanelLogic';
import { SkeletonContainer } from '@shared/components/SkeletonContainer/SkeletonContainer';
import { useMemo, useState } from 'react';
import { FieldSelectionFilters } from '../FieldSelectionFilters/FieldSelectionFilters';
import { FieldSelectionMap } from '../FieldSelectionMap/FieldSelectionMap';
import { DuplicationModalRightPanelFooter } from '../Duplication/DuplicationModalRightPanelFooter/DuplicationModalRightPanelFooter';

/** This Panel display the list of field with the map on the side.\
 * It require the `fieldSelectionSlice` usage. */
export const FieldSelectionPanel = ({
    description,
    isLoading,
    onDeselect,
    onSelect,
    title,
    excludedFieldIds,
    onButtonAction,
    isButtonDisabled,
    buttonLabel,
    isDuplication,
    setIsDoingCopy,
}: {
    onSelect: (fieldId: number) => void;
    onDeselect: (fieldId: number) => void;
    title: string;
    description: string;
    isLoading: boolean;
    excludedFieldIds?: number[];
    // Action button (not mandatory)
    onButtonAction?: () => Promise<unknown>;
    isButtonDisabled?: boolean;
    buttonLabel?: string;
    isDuplication?: boolean;
    setIsDoingCopy?: (value: boolean) => void;
}) => {
    const { classes } = useFieldSelectionPanelStyle();
    const { fieldList } = useFieldSelectionPanelLogic();
    const [filteredFieldIds, setFilteredFieldIds] = useState<Set<number> | null>(null);
    const fieldIds = useMemo(() => fieldList.map((field) => field.id), [fieldList]);

    return (
        <SidePanel
            className={classes.panel}
            header={
                <SidePanel.Header>
                    <SkeletonContainer
                        isLoading={isLoading}
                        Content={
                            <>
                                <Typography variant="h3" mb={2}>
                                    {title}
                                </Typography>
                                <Typography color="neutral" variant="body2" mb={2}>
                                    {description}
                                </Typography>
                            </>
                        }
                        Skeletons={
                            <>
                                <Skeleton variant="text" width={250} height={40} sx={{}} />
                                <Skeleton variant="text" width={'auto'} height={30} sx={{}} />
                                <Skeleton variant="text" width={'50%'} height={30} sx={{}} />
                            </>
                        }
                    />
                </SidePanel.Header>
            }
            body={
                <SidePanel.Body className={classes.relative}>
                    <FieldSelectionFilters
                        onFilteredIdsChange={setFilteredFieldIds}
                        isLoading={isLoading}
                        isDuplication={isDuplication}
                    />

                    <FieldSelectionList
                        onDeselect={onDeselect}
                        onSelect={onSelect}
                        filteredFieldIds={filteredFieldIds}
                        isLoading={isLoading}
                        excludedFieldIds={excludedFieldIds}
                        isDuplication={isDuplication}
                    />
                </SidePanel.Body>
            }
            footer={
                onButtonAction ? (
                    <DuplicationModalRightPanelFooter
                        onButtonAction={onButtonAction}
                        buttonLabel={buttonLabel}
                        isButtonDisabled={isButtonDisabled}
                        setIsDoingCopy={setIsDoingCopy}
                    />
                ) : (
                    <></>
                )
            }
        >
            <MapRoot />
            <FieldSelectionMap
                onSelect={onSelect}
                onDeselect={onDeselect}
                initialFieldIdsfocused={fieldIds}
                isDuplication={isDuplication}
                excludedFieldIds={excludedFieldIds}
            />
        </SidePanel>
    );
};
